<template>
    <modal :show="show">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title m-0" id="exampleModalDefaultLabel">Create Authorization Letter</h6>
                <button type="button" class="btn-close" v-on:click="close()"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group col-sm-12">
                        <label>
                            Signature Type :
                        </label>
                        <div>
                            <multiselect v-model="currency.type" :preselect-first="false" :options="['name', 'image']"
                                :show-labels="false" placeholder="Select Signature Type">
                            </multiselect>
                        </div>
                    </div>





                    <div v-if="currency.type == 'name'" class="form-group col-sm-12">
                        <label>Name</label>
                        <input class="form-control" v-model="currency.name" type="text" />
                    </div>

                    <div class="form-group col-sm-12"  v-if="currency.type == 'image'">
                        <div :key="renderImg">
                            <div class="input-group mb-3"
                                v-if="!((imageSrc == '' && currency.image != '') || (imageSrc != '' && currency.image == '') || (imageSrc != '' && currency.image != ''))">
                                <input ref="imgupload" type="file" class="form-control" id="inputGroupFile02"
                                    @change="uploadImage()" accept="image/*" name="image">
                            </div>



                            <div class="text-right " v-if="imageSrc != ''">
                                <img v-if="imageSrc != ''" class="float-right" :src="imageSrc" width="100" />
                            </div>
                            <div v-else class="text-right ">
                                <img v-if="currency.image != null && currency.image != ''" class="float-right"
                                    :src="'data:image/png;base64,' + currency.image" width="100" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-sm-12" v-if="imageSrc != '' || currency.image != ''">
                        <div class="text-right">
                            <button class="btn btn-danger  btn-sm" v-on:click="removeImage()">{{ $t('AddCurrency.Remove')
                            }}</button>
                        </div>
                    </div>



                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-soft-primary btn-sm" v-on:click="SaveCustomer">
                    {{
                        $t('AddCurrency.btnSave')
                    }}
                </button>

                <button type="button" class="btn btn-soft-secondary btn-sm" v-on:click="close()">
                    {{
                        $t('AddCurrency.btnClear')
                    }}
                </button>
            </div>
            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
        </div>



    </modal>
</template>
<script>
import clickMixin from '@/Mixins/clickMixin'
import Multiselect from 'vue-multiselect'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default ({
    mixins: [clickMixin],
    props: ['show', 'currency', 'type', 'setup'],
    components: {
        Loading,
        Multiselect,


    },
    data: function () {
        return {
            loading: false,
            render: 0,
            imageSrc: '',
            arabic: '',
            english: '',
            customer: '',
            stepsVm: {
                companyId: '',
                step1: false,
                step2: false,
                step3: false,
                step4: false,
            },
            // currency:{
            //     type:'',
            //     name:'',
            //     image:'',
            // },
            renderImg: 0
        }
    },

    methods: {
        SaveCustomer: function () {

            this.customer.code = this.contactCode;

            this.loading = true;
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.customer.signatureType = this.currency.type;
            this.customer.signatureName = this.currency.name;
            this.customer.signaturePath = this.currency.image;
            this.customer.isCaseCustomer = true;


            root.$https
                .post('/Contact/SaveContact', this.customer, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(response => {
                    if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                        root.loading = false
                        root.info = response.data.bpi

                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully' : 'حفظ بنجاح',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved' : 'تم الحفظ',
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        }).then(function (ok) {
                            if (ok != null) {
                                root.close()
                            }
                        });
                    } else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                        root.loading = false
                        root.info = response.data.bpi

                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully' : 'حفظ بنجاح',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved' : 'تم الحفظ',
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        }).then(function (ok) {
                            root.close();
                            console.log(ok)
                        });
                    } else {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'There is something wrong. Please contact to support.' : 'هناك شيء ما خاطئ. يرجى الاتصال للدعم.',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }

                })
                .catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                        text: error.response.data,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });

                    root.loading = false
                })
                .finally(() => root.loading = false)
        },
        getCustomerDetail: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            var email = localStorage.getItem('UserName');

            this.$https.get('/Contact/ContactDetail?caseCustomer=true' + '&email=' + email, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    root.customer = response.data;

                }

            });
        },
        removeImage: function () {
            this.imageSrc = '';
            this.currency.image = '';
            this.renderImg++;

        },
        uploadImage: function () {
            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }

            var file = this.$refs.imgupload.files;

            var fileData = new FormData();

            for (var k = 0; k < file.length; k++) {
                fileData.append("files", file[k]);
            }

            this.imageSrc = URL.createObjectURL(this.$refs.imgupload.files[0]);

            root.$https.post('/Company/UploadFilesAsync', fileData, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {

                        root.currency.image = response.data;
                    }
                },
                    function () {
                        this.loading = false;
                        root.$swal({
                            title: root.$t('AddCurrency.Error'),
                            text: root.$t('AddCurrency.SomethingWrong'),
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            buttonsStyling: false
                        });
                    });
        },

        close: function () {
            this.$emit('close');
        },
        SaveCurrency: function () {
            var root = this;

            var url = '/Product/SaveCurrency';
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            root.$https
                .post(url, root.currency, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data.isSuccess == true) {
                        if (root.type != "Edit") {
                            root.$store.state.currencyList.push({
                                id: response.data.currency.id,
                                name: response.data.currency.name,
                                nameArabic: response.data.currency.nameArabic,
                                sign: response.data.currency.sign,
                                arabicSign: response.data.currency.arabicSign,
                                image: response.data.currency.image,
                                isActive: response.data.currency.isActive
                            })
                            root.$swal({
                                text: root.$t('AddCurrency.Saved'),
                                title: root.$t('AddCurrency.SavedSuccessfully'),
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 800,
                                timerProgressBar: true,
                            });
                            root.close();
                            if (root.setup) {
                                root.$emit('CurrencySave', true);
                                localStorage.setItem('currency', response.data.currency.sign);
                            }
                        }
                        else {
                            var data = root.$store.state.currencyList.find(function (x) {
                                return x.id == response.data.currency.id;
                            });
                            data.id = response.data.currency.id;
                            data.name = response.data.currency.name;
                            data.nameArabic = response.data.currency.nameArabic;
                            data.sign = response.data.currency.sign;
                            data.arabicSign = response.data.currency.arabicSign;
                            data.isActive = response.data.currency.isActive;
                            root.$swal({
                                title: root.$t('Updated'),
                                text: root.$t('UpdateSuccessfully'),
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 800,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                    }
                    else {
                        root.$swal({
                            title: root.$t('Error'),
                            text: root.$t('NameAlreadyExist'),
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 800,
                            timerProgressBar: true,
                        });
                    }
                });
        }
    },
    mounted: function () {
        this.getCustomerDetail();
        this.english = 'true';
        this.arabic = localStorage.getItem('Arabic');
        if (this.$route.query.data != undefined) {
            this.currency = this.$route.query.data;
        }
    }
})

</script>